import { createContext, useContext, useState } from "react";
import { addItem, deleteItem, updateItem } from "../utils/fetch.utils";

const ItemsContext = createContext(null);

export const ItemsProvider = ({ children }) => {
  const [items, setItems] = useState({ loading: true });
  const [scoresVisible, setScoresVisible] = useState(false);

  const handleScoresVisible = () => {
    setScoresVisible((prevState) => !prevState);
    localStorage.setItem("coadmin-viewscores", !scoresVisible);
  };

  const handleAdd = async (collectionId, data) => {
    const resp = await addItem(collectionId, data);
    if (resp._id) {
      setItems({
        count: items.count + 1,
        documents: [...items.documents, resp],
      });
      return resp;
    } else {
      // TODO: deal with api error here...
    }
  };

  const handleUpdate = async (collectionId, itemId, data) => {
    const resp = await updateItem(collectionId, itemId, data);
    if (resp._id) {
      const updatedDocs = document.map((doc) =>
        doc._id === itemId ? { ...doc, ...data } : doc
      );
      setItems((prevState) => ({ ...prevState, documents: updatedDocs }));
    }
  };

  const handleDelete = async (collectionId, _id) => {
    const resp = await deleteItem(collectionId, _id);
    if (resp._id) {
      setItems({
        count: items.count - 1,
        documents: items.documents.filter((item) => item._id !== _id),
      });
    } else {
      // TODO: handle error nicely
      console.error("there was a problem");
    }
  };

  const setCurrentItem = (current) => {
    setItems((prevState) => ({ ...prevState, loading: false, current }));
  };

  return (
    <ItemsContext.Provider
      value={{
        ...items,
        setItems,
        handleAdd,
        handleUpdate,
        handleDelete,
        handleScoresVisible,
        scoresVisible,
        setCurrentItem,
      }}
    >
      {children}
    </ItemsContext.Provider>
  );
};

export default function useItemsContext() {
  return useContext(ItemsContext);
}
