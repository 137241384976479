import * as React from "react";
import EmbedPreview from "../../../components/EmbedPreview";
import useCollectionsContext from "../../../context/CollectionsContext";
import { Box, Button } from "@mui/material";
import useItemsContext from "../../../context/ItemsContext";

const Embed = ({ previewScript, setPreviewScriptState }) => {
  const { current: currentColl } = useCollectionsContext();
  const { handleScoresVisible, scoresVisible } = useItemsContext();

  const { container_id } = currentColl;

  const previewIsVisible = Boolean(previewScript?.js);

  return (
    <Box sx={{ paddingTop: "1rem" }}>
      <Button
        variant="contained"
        sx={{ marginRight: "1rem" }}
        onClick={() => {
          setPreviewScriptState(!previewIsVisible);
        }}
      >
        {previewIsVisible ? "Hide" : "Render"} Preview
      </Button>

      {previewIsVisible ? (
        <Button
          variant="outlined"
          onClick={() => {
            handleScoresVisible();
            setPreviewScriptState(false);
          }}
        >
          {scoresVisible ? "Hide" : "Show"} Search Scores
        </Button>
      ) : null}

      {previewIsVisible ? (
        <EmbedPreview {...{ container_id, previewScript }} />
      ) : null}
    </Box>
  );
};

export default Embed;
